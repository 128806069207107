<script setup lang="ts">
definePageMeta({
  // title: 'Welkom bij Stagedoos',
  // description: 'Welkom bij Stagedoos',
  isPublic: true,
})

const { isAuthenticated } = useAuth()
if (isAuthenticated.value) navigateTo('/dashboard')

const { appName } = useAppName()
const { tenant } = useTenant()
</script>

<template>
  <div class="flex flex-col gap-4">
    <template v-if="tenant == 'match2gether'">
      <PageTitle>
        Welkom op {{ appName }}!
      </PageTitle>

      <BaseParagraph>
        Match2gether is het online stage-platform van het Udens College, Fioretti College en Elde College.
      </BaseParagraph>
    </template>

    <template v-else-if="tenant == 'mas4you'">
      <div class="flex max-w-2xl flex-col gap-4">
        <PageTitle>Welkom op de website voor maatschappelijke stages in regio Noord-Veluwe!</PageTitle>

        <BaseParagraph>
          Op deze website kunnen leerlingen uit Elburg, Ermelo, Harderwijk, Nunspeet, Oldebroek, Zeewolde en Putten maatschappelijke stages zoeken.
        </BaseParagraph>

        <BaseMessage type="info">
          Mas4you wordt vernieuwd! We zijn druk bezig om deze website leuker en makkelijker te maken.
        </BaseMessage>
      </div>
    </template>

    <div>
      <BaseButton color="primary" to="/inloggen">
        Klik hier om in te loggen
      </BaseButton>
    </div>

    <div v-if="tenant == 'mas4you'" class="mt-4">
      <BaseHeading as="h3" class="mb-4">
        Stage aanbieden?
      </BaseHeading>

      <BaseParagraph>
        Heb je een leuke stage voor leerlingen uit de regio Noord-Veluwe? Meld je dan aan als organisatie.
      </BaseParagraph>

      <BaseButton to="/organizations/signup" class="mt-4">
        Aanmelden organisatie
      </BaseButton>
    </div>
  </div>
</template>
